import React, { useRef, useCallback, useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Toolbar from './Toolbar/Toolbar';
import {
  ViewWindowType,
  ResizeType,
  phaseOfFlightItems,
  airlineItems,
  assignmentListItems,
} from '../../../../../lib/constants';
import useDraggableViewWindow from '../../../../../hooks/useDraggableViewWindow/useDraggableViewWindow';
import useResizableViewWindow from '../../../../../hooks/useResizableViewWindow/useResizableViewWindow';
import { useWindowManagerDispatch } from '../../../../../hooks/useWindowManagerStore/useWindowManagerStore';
import { calculateMinimizedPosition, dragEndCallback, resizeEndCallback, getWindowStyles } from './utils';
import './ViewWindow.css';
import useDefaultRoleFilters from '../../../../../hooks/useDefaultRoleFilters/useDefaultRoleFilters';
import { useFilterStore } from '../../../../../hooks/useFilterStore/useFilterStore';
import { getDefaultFilter } from '../../../../../lib/utils';

const ViewWindow = ({
  variant,
  title,
  isFocused = true,
  onFocusToWindow = () => {},
  children,
  onClose,
  windowPosition,
  id,
  isMinimized,
  minimizedIndex,
  isMaximized,
  currentActiveId,
  ...viewData
}) => {
  useDefaultRoleFilters();

  const ref = useRef(null);
  const {
    setMinimizedView,
    removeMinimizedView,
    setMaximizedView,
    removeMaximizedView,
    setDraggedMiniView,
    updateViewWindow,
  } = useWindowManagerDispatch();
  const [initialMinimizedPos, setInitialMinimizedPos] = useState({ x: 0, y: 0 });

  const [searchFilterTitle, setSearchFilterTitle] = useState(null);
  const { filter, isDefaultFilter, defaultAssignmentFilter } = useFilterStore();
  const defaultFilter = useMemo(() => getDefaultFilter(defaultAssignmentFilter), [defaultAssignmentFilter]);

  const getDefaultViewTitle = () => {
    return isDefaultFilter ? "Today's Rotation" : searchFilterTitle;
  };

  const getTitleFromSearchFilter = (filter, defaultFilter) => {
    const { origin, destination, aircraft, flight, airline, fleets, alerts, flightPhase, assignmentList } = filter;

    const titleParts = [];

    // Add origin and destination, ensuring no duplication if they are the same
    if (origin.length && destination.length) {
      titleParts.push(origin[0] === destination[0] ? origin[0] : `${origin[0]}|${destination[0]}`);
    } else {
      if (origin.length) titleParts.push(origin[0]);
      if (destination.length) titleParts.push(destination[0]);
    }

    const fields = [
      { values: aircraft, defaultValue: defaultFilter.aircraft },
      { values: flight, defaultValue: defaultFilter.flight },
      { values: airline, defaultValue: defaultFilter.airline, items: airlineItems },
      { values: fleets, defaultValue: defaultFilter.fleets },
      { values: alerts, defaultValue: defaultFilter.alerts },
      { values: flightPhase, defaultValue: defaultFilter.flightPhase, items: phaseOfFlightItems },
      { values: assignmentList, defaultValue: defaultFilter.assignmentList, items: assignmentListItems },
    ];

    fields.forEach(({ values, defaultValue, items }) => {
      values.forEach((value) => {
        const normalizedDefaultValue =
          Array.isArray(defaultValue) && defaultValue.length === 1 ? defaultValue[0] : defaultValue;
        if (value !== normalizedDefaultValue) {
          if (items) {
            const item = items.find((item) => item.key === value);
            if (item) titleParts.push(item.name);
          } else {
            titleParts.push(value);
          }
        }
      });
    });

    // Join parts with '|' and ensure the title does not exceed 75 characters
    let title = titleParts.join('|');
    return title.length > 75 ? title.substring(0, 75) : title;
  };

  useEffect(() => {
    if (title === null && !isDefaultFilter) {
      setSearchFilterTitle(getTitleFromSearchFilter(filter, defaultFilter));
    }
  }, [isDefaultFilter, filter, title]);

  const canDragWindow = useMemo(() => {
    return variant === ViewWindowType.MULTI;
  }, [variant]);

  const canResizeWindow = useMemo(() => {
    return variant === ViewWindowType.MULTI && !isMinimized;
  }, [variant, isMinimized]);

  const draggableRef = useDraggableViewWindow({
    dragHandleElementId: 'toolbar-drag-area',
    canDrag: canDragWindow,
    dragEndCallback: (refCurrent) => {
      dragEndCallback(
        refCurrent,
        id,
        setDraggedMiniView,
        initialMinimizedPos,
        isMinimized,
        viewData.viewPosition,
        updateViewWindow,
      );
    },
  });

  const resizableViewWindowParams = {
    containerRef: ref,
    canResize: canResizeWindow,
    resizeEndCallback: (refCurrent) => {
      resizeEndCallback(refCurrent, id, updateViewWindow);
    },
  };

  const resizeTopHandleRef = useResizableViewWindow({
    resizeType: ResizeType.TOP,
    ...resizableViewWindowParams,
  });
  const resizeRightHandleRef = useResizableViewWindow({
    resizeType: ResizeType.RIGHT,
    ...resizableViewWindowParams,
  });
  const resizeBottomHandleRef = useResizableViewWindow({
    resizeType: ResizeType.BOTTOM,
    ...resizableViewWindowParams,
  });
  const resizeLeftHandleRef = useResizableViewWindow({
    resizeType: ResizeType.LEFT,
    ...resizableViewWindowParams,
  });
  const resizeBottomRightHandleRef = useResizableViewWindow({
    resizeType: ResizeType.BOTTOM_RIGHT,
    ...resizableViewWindowParams,
  });
  const resizeBottomLeftHandleRef = useResizableViewWindow({
    resizeType: ResizeType.BOTTOM_LEFT,
    ...resizableViewWindowParams,
  });
  const resizeTopLeftHandleRef = useResizableViewWindow({
    resizeType: ResizeType.TOP_LEFT,
    ...resizableViewWindowParams,
  });
  const resizeTopRightHandleRef = useResizableViewWindow({
    resizeType: ResizeType.TOP_RIGHT,
    ...resizableViewWindowParams,
  });

  const { className, style } = getWindowStyles({
    variant,
    isMaximized,
    isMinimized,
    minimizedIndex,
    calculateMinimizedPosition,
    initialMinimizedPos,
    setInitialMinimizedPos,
    viewData,
    windowPosition,
  });

  const attachRefs = useCallback(
    (el) => {
      ref.current = el;
      draggableRef.current = el;
    },
    [draggableRef],
  );

  const handleMaximize = useCallback(() => {
    if (isMaximized) {
      removeMaximizedView(id);
    } else {
      setMaximizedView(id);
    }
    removeMinimizedView(id);
  }, [isMaximized, id, removeMaximizedView, setMaximizedView]);

  const handleMinimize = useCallback(() => {
    if (!isMinimized) {
      removeMaximizedView(id);
      setMinimizedView(id);
    }
  }, [isMinimized, setMinimizedView, id, removeMaximizedView, minimizedIndex]);

  const handleCloseClick = () => {
    onClose();
  };

  const handleFocusToWindow = () => {
    onFocusToWindow();
  };

  /**
   * Renders the toolbar component based on the current view window type.
   * @returns {JSX.Element|null} The Toolbar component if the view window type is not SWAP_MODE, otherwise null.
   */
  const renderToolbar = () => {
    if (variant === ViewWindowType.SWAP_MODE) return null;
    return (
      <Toolbar
        title={title ?? getDefaultViewTitle()}
        variant={variant}
        onMaximize={handleMaximize}
        onMinimize={handleMinimize}
        onClose={handleCloseClick}
        isMinimized={isMinimized}
        currentActiveId={currentActiveId}
      />
    );
  };

  return (
    <div
      data-cy="view-window"
      className={`view-window${isFocused ? ' focused' : ''}${className}${isMaximized ? ' maximized' : ''}`}
      style={style}
      ref={attachRefs}
      onMouseDown={handleFocusToWindow}
    >
      {renderToolbar()}
      {<div className="view-window-content">{children}</div>}
      {canResizeWindow && (
        <div>
          <div className="resize-corner" id="resize-bottom-right" ref={resizeBottomRightHandleRef} />
          <div className="resize-corner" id="resize-bottom-left" ref={resizeBottomLeftHandleRef} />
          <div className="resize-corner" id="resize-top-right" ref={resizeTopRightHandleRef} />
          <div className="resize-corner" id="resize-top-left" ref={resizeTopLeftHandleRef} />
          <div className="resize-side" id="resize-right" ref={resizeRightHandleRef} />
          <div className="resize-side" id="resize-left" ref={resizeLeftHandleRef} />
          <div className="resize-side" id="resize-bottom" ref={resizeBottomHandleRef} />
          <div className="resize-side" id="resize-top" ref={resizeTopHandleRef} />
        </div>
      )}
    </div>
  );
};

ViewWindow.propTypes = {
  variant: PropTypes.string.isRequired,
  title: PropTypes.string,
  isFocused: PropTypes.bool,
  onFocusToWindow: PropTypes.func,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
  windowPosition: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  isMinimized: PropTypes.bool,
  minimizedIndex: PropTypes.number,
  isMaximized: PropTypes.bool,
  isSwapModeActive: PropTypes.bool,
  currentActiveId: PropTypes.number.isRequired,
};

export default ViewWindow;
