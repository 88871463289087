import { combineReducers } from 'redux';
import filterReducer from './filterReducer';
import selectedFlightDetailsReducer from './selectedFlightDetailsReducer';
import autoRefreshReducer from './autoRefreshReducer';
import hideCancelledFlightsReducer from './hideCancelledFlightsReducer';
import swapModeReducer from './swapModeReducer';
import dragDropReducer from './dragDropReducer/dragDropReducer';
import configReducer from './configReducer';
import crosshairReducer from './crosshairReducer';
import flightPuckRefReducer from './flightPuckRefReducer/flightPuckRefReducer';
import multiSelectedFlightGroupReducer from './multiSelectedFlightGroupReducer';
import summaryPanelReducer from './summaryPanelReducer';
import focusedFlightLegReducer from './focusedFlightLegReducer';
import searchFlightReducer from './searchFlightReducer';
import viewIdReducer from './viewIdReducer';

const rootReducer = combineReducers({
  id: viewIdReducer,
  filterReducer,
  selectedFlightDetailsReducer,
  autoRefreshReducer,
  hideCancelledFlightsReducer,
  swapModeReducer,
  dragDropReducer,
  crosshairReducer,
  configReducer,
  flightPuckRefReducer,
  multiSelectedFlightGroupReducer,
  summaryPanelReducer,
  focusedFlightLegReducer,
  searchFlightReducer,
});

export default rootReducer;
