import { FlightView } from '../../lib/constants';
import { WindowManagerAction } from '../actionTypes';

// Default viewWindow object
const defaultViewWindow = {
  id: 0,
  viewTitle: null,
  viewType: FlightView.GANTT_VIEW,
  viewPosition: { x: 0, y: 0 },
  viewSize: { width: 800, height: 600 },
};

const getInitialState = () => {
  // Initialize viewWindows with values from sessionStorage if available
  const viewWindowsFromSessionStorage = JSON.parse(sessionStorage.getItem('windowManagerStore'));
  if (viewWindowsFromSessionStorage) {
    return viewWindowsFromSessionStorage;
  }
  return {
    viewWindows: [defaultViewWindow],
    viewOrder: [0],
    minimizedViews: [],
    maximizedViews: [],
    draggedMinimizedViews: [],
  };
};

// Reducer function
function windowManagerReducer(state = getInitialState(), action) {
  switch (action.type) {
    case WindowManagerAction.ADD_VIEW_WINDOW:
      const newId = state.viewWindows.reduce((acc, curr) => (curr.id >= acc ? curr.id + 1 : acc), 0);
      const newViewWindow = {
        ...defaultViewWindow,
        id: newId,
      };
      return {
        ...state,
        viewWindows: [...state.viewWindows, newViewWindow],
        viewOrder: [newId, ...state.viewOrder],
      };

    case WindowManagerAction.REMOVE_VIEW_WINDOW: {
      const updatedViewWindows = state.viewWindows.filter((view) => view.id !== action.id);
      const updatedViewOrder = state.viewOrder.filter((id) => id !== action.id);
      const updatedMinimizedViews = state.minimizedViews.filter((id) => id !== action.id);
      const updatedMaximizedViews = state.maximizedViews.filter((id) => id !== action.id);
      const updatedDraggedMinimizedViews = state.draggedMinimizedViews.filter((id) => id !== action.id);
      return {
        ...state,
        viewWindows: updatedViewWindows,
        viewOrder: updatedViewOrder,
        minimizedViews: updatedMinimizedViews,
        maximizedViews: updatedMaximizedViews,
        draggedMinimizedViews: updatedDraggedMinimizedViews,
      };
    }

    case WindowManagerAction.UPDATE_VIEW_WINDOW:
      const updatedWindows = state.viewWindows.map((window) =>
        window.id === action.id ? { ...window, ...action.payload } : window,
      );
      return {
        ...state,
        viewWindows: updatedWindows,
      };

    case WindowManagerAction.SET_ACTIVE_VIEW:
      const newViewOrder = state.viewOrder.filter((id) => id !== action.id);
      return {
        ...state,
        viewOrder: [action.id, ...newViewOrder],
      };

    case WindowManagerAction.SET_MINIMIZED_VIEW:
      const newStateAfterSetMinimized = {
        ...state,
        minimizedViews: [...state.minimizedViews, action.id],
      };
      return newStateAfterSetMinimized;

    case WindowManagerAction.REMOVE_MINIMIZED_VIEW: {
      const newStateAfterRemoveMinimized = {
        ...state,
        minimizedViews: state.minimizedViews.filter((id) => id !== action.id),
        draggedMinimizedViews: state.draggedMinimizedViews.filter((id) => id !== action.id),
      };
      return newStateAfterRemoveMinimized;
    }

    case WindowManagerAction.SET_DRAGGED_MINIMIZED_VIEW: {
      const newStateAfterSetDraggedMinimized = {
        ...state,
        minimizedViews: state.minimizedViews.filter((id) => id !== action.id),
        draggedMinimizedViews: [...state.draggedMinimizedViews.filter((id) => id !== action.id), action.id],
      };
      return newStateAfterSetDraggedMinimized;
    }

    case WindowManagerAction.SET_MAXIMIZED_VIEW:
      const newStateAfterSetMaximized = {
        ...state,
        maximizedViews: [...state.maximizedViews, action.id],
      };
      return newStateAfterSetMaximized;

    case WindowManagerAction.REMOVE_MAXIMIZED_VIEW: {
      const newStateAfterRemoveMaximized = {
        ...state,
        maximizedViews: state.maximizedViews.filter((id) => id !== action.id),
      };
      return newStateAfterRemoveMaximized;
    }

    case WindowManagerAction.RENAME_VIEW_WINDOW: {
      const renameWindow = state.viewWindows.map((window) =>
        window.id === action.id ? { ...window, ...action.payload } : window,
      );
      return {
        ...state,
        viewWindows: renameWindow,
      };
    }

    default:
      return state;
  }
}

export default windowManagerReducer;
