import { getTransformValues, getWidthHeightValues } from '../../../../../lib/htmlElementUtils/htmlElementUtils';
import { ViewWindowType } from '../../../../../lib/constants';

/**
 * @description Calculate the position of the minimized view
 * @param {number} index - index of minimized view
 * @returns
 */
export const calculateMinimizedPosition = (index) => {
  const rowCapacity = Math.floor(window.innerWidth / 200); // Number of windows per row
  const row = Math.floor(index / rowCapacity);
  const col = index % rowCapacity;
  const x = 15 + col * 200; // 200px width per window, starting from 15px
  const y = window.innerHeight - (row + 1) * 35 - 120;
  return { x, y };
};

/**
 * @description Callback when minimized view dragging ends
 * @param {HTMLElement} refCurrent - reference to the view window
 * @param {number} id - view id
 * @param {boolean} isMinimized - whether the view is minimized
 * @param {function} setDraggedMiniView - function to set the dragged minimized view
 * @param {object} initialMinimizedPos - initial position of the minimized view
 * @returns
 */
export const dragEndCallback = (
  refCurrent,
  id,
  setDraggedMiniView,
  initialMinimizedPos,
  isMinimized,
  viewPosition,
  updateViewWindow,
) => {
  const { translateX, translateY } = getTransformValues(refCurrent);
  const windowUpdate = {};
  if (isMinimized) {
    const { x, y } = viewPosition;
    if (x && y) {
      windowUpdate.viewPosition = { x, y, minimized: { x: translateX, y: translateY } };
    } else {
      windowUpdate.viewPosition = { minimized: { x: translateX, y: translateY } };
    }
    const { height, width } = refCurrent.getBoundingClientRect();
    const { x: initialX, y: initialY } = initialMinimizedPos;
    if (translateY < initialY - height || translateX > initialX + width) {
      setDraggedMiniView(id);
    }
  } else {
    windowUpdate.viewPosition = { x: translateX, y: translateY };
  }
  updateViewWindow(id, windowUpdate);
};

export const resizeEndCallback = (refCurrent, id, updateViewWindow) => {
  const { width, height } = getWidthHeightValues(refCurrent);
  const { translateX, translateY } = getTransformValues(refCurrent);
  updateViewWindow(id, { viewSize: { width, height }, viewPosition: { x: translateX, y: translateY } });
};

const checkSetInitialMinimizedPos = (initialMinimizedPos, setInitialMinimizedPos, x, y) => {
  if (initialMinimizedPos.x !== x || initialMinimizedPos.y !== y) {
    setInitialMinimizedPos({ x, y });
  }
};

export const getWindowStyles = ({
  variant,
  isMaximized,
  isMinimized,
  minimizedIndex,
  calculateMinimizedPosition,
  initialMinimizedPos,
  setInitialMinimizedPos,
  viewData,
  windowPosition,
}) => {
  const { x, y, minimized } = viewData.viewPosition || { x: 50, y: 50, minimized: { x: 0, y: 0 } };
  const { width, height } = viewData.viewSize || { width: 800, height: 500 };

  if (variant === ViewWindowType.MULTI && isMaximized) {
    return {
      className: ' multi',
      style: {
        transform: 'translate(0px, 0px)',
        height: '100%',
        width: '100%',
        zIndex: 999,
      },
    };
  } else if (variant === ViewWindowType.MULTI && isMinimized) {
    if (minimizedIndex !== null) {
      const { x, y } = calculateMinimizedPosition(minimizedIndex);

      checkSetInitialMinimizedPos(initialMinimizedPos, setInitialMinimizedPos, x, y);

      return {
        className: ' multi',
        style: {
          transform: `translate(${x}px, ${y}px)`,
          width: '200px',
          height: '35px',
          zIndex: 999,
        },
      };
    } else if (minimized?.x && minimized?.y) {
      return {
        className: ' multi',
        style: {
          transform: `translate(${minimized.x}px, ${minimized.y}px)`,
          width: '200px',
          height: '35px',
          zIndex: 999,
        },
      };
    } else {
      return {
        className: ' multi',
        style: {
          width: '200px',
          height: '35px',
          zIndex: 999,
        },
      };
    }
  } else if (variant === ViewWindowType.MULTI) {
    return {
      className: ' multi',
      style: {
        transform: `translate(${x}px, ${y}px)`,
        height: `${height}px`,
        width: `${width}px`,
        zIndex: 999 - windowPosition,
      },
    };
  } else if (variant === ViewWindowType.SINGLE) {
    return {
      className: ' single',
      style: {
        transform: 'translate(0px, 0px)',
        height: '100%',
        width: '100%',
      },
    };
  } else if (variant === ViewWindowType.SWAP_MODE) {
    return {
      className: ' swap-mode',
      style: {
        transform: 'translate(0px, 0px)',
        height: '100%',
        width: '100%',
      },
    };
  }
};
