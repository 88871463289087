import { axiosInstance } from '../apiClient';
import {
  getApiErrorMessage,
  logError,
  logInformation,
  createApiErrorTelemetryProperties,
} from '../../../lib/appInsightsUtils';

const API_ROUTE = 'swapwarnings';
const moduleName = 'swapsWarningsApi';

/**
 * @description Swaps Warnings API Call
 * @param {object} swapsWarningsPayload - payload with swaps warnings
 * @returns {Object} ApiStatusResult with success or failure
 */
export const swapsWarningsApi = async (swapsWarningsPayload) => {
  const apiClient = axiosInstance;
  let result = null;

  const loggingData = swapsWarningsPayload
    .map((ac) => {
      if (ac.flightLegs?.length > 2) {
        const output = {
          ...ac,
          flightLegs: ac.flightLegs.slice(0, 2),
        };
        let outputString = JSON.stringify(output, null, 2);
        const insertIndex = outputString.lastIndexOf(']');
        const insertString = `  ...[${ac.flightLegs.length - 2} more flights]\n  `;
        outputString = `${outputString.slice(0, insertIndex)}${insertString}${outputString.slice(insertIndex)}`;
        return outputString;
      } else {
        return JSON.stringify(ac, null, 2);
      }
    })
    .join(',\n');

  try {
    logInformation(
      `Submitting Swaps Warnings API POST request for payload: \n${loggingData}`,
      moduleName,
      'swapwarnings',
    );
    const url = `${API_ROUTE}/api/v1/SwapWarnings/submit`;
    const response = await apiClient.post(url, swapsWarningsPayload);
    result = response.data;
  } catch (error) {
    logError(getApiErrorMessage(error), moduleName, 'swapwarnings', createApiErrorTelemetryProperties(error));
    console.error(error);
  }
  return result;
};
