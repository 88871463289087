import { useMemo } from 'react';
import { useThemeContext } from '../../../../contexts/ThemeContext/ThemeContext';
import { ThemeMode, Treatment } from '../../../../lib/constants';
import { getAircraftLabelColor } from '../ganttHelpers';
import PropTypes from 'prop-types';
import TailNumberIndicators from './TailNumberIndicators';

import './AircraftLabel.css';
import { useFeatureFlag } from '../../../../contexts/FeatureFlagContext/FeatureFlagContext';

/**
 * Renders an aircraft label for the gantt chart aircraft axis
 * @param {Object} props
 * @param {string} props.aircraft
 * @param {string} props.airline
 * @param {string} props.subfleetType
 * @param {number} props.totalRows
 * @param {string} props.className
 * @returns
 */
const AircraftLabel = ({
  aircraft,
  airline,
  subfleetType,
  totalRows,
  className,
  tailNumberIndicators,
  tailNumberIndicatorsFlag,
  enhancedGanttRowHeight = null,
}) => {
  const { showFeature } = useFeatureFlag();
  const enableEnhancedScalingFlag = showFeature(Treatment.SCALING_BUTTON);
  const { currentTheme, businessRulesStyles } = useThemeContext();

  const aircraftLabelColor = useMemo(() => {
    const aircraftLabelColors = businessRulesStyles?.aircraftLabelColors;
    if (currentTheme === ThemeMode.CLASSIC && aircraftLabelColors) {
      const aircraftInfo = {
        aircraft,
        airline,
        fleetType: subfleetType,
      };
      return getAircraftLabelColor(aircraftInfo, aircraftLabelColors);
    }

    return null;
  }, [currentTheme, businessRulesStyles, aircraft, airline, subfleetType]);

  const labelStyle =
    enableEnhancedScalingFlag && enhancedGanttRowHeight
      ? {
          height: `${totalRows * enhancedGanttRowHeight}px`,
          lineHeight: totalRows === 1 ? `${totalRows * enhancedGanttRowHeight}px` : '',
        }
      : {
          height: `calc(${totalRows} * var(--gantt-row-height))`,
          lineHeight: totalRows === 1 ? `calc(${totalRows} * var(--gantt-row-height))` : '',
        };

  if (aircraftLabelColor) {
    labelStyle.color = aircraftLabelColor;
  }

  // truncate "N" from aircraft registration label
  const displayText = aircraft.startsWith('N') ? aircraft.slice(1) : aircraft;

  let classes = 'aircraft-label-container';
  if (className) {
    classes += ` ${className}`;
  }
  if (tailNumberIndicatorsFlag) {
    classes += ' tail-number-indicators';
  }

  return (
    <div className={classes} style={labelStyle} data-cy={`aircraft-label-${aircraft}`}>
      {displayText}
      {tailNumberIndicatorsFlag ? (
        <TailNumberIndicators
          tailNumberIndicators={tailNumberIndicators}
          currentTheme={currentTheme}
          totalRows={totalRows}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default AircraftLabel;

AircraftLabel.propTypes = {
  aircraft: PropTypes.string.isRequired,
  airline: PropTypes.string.isRequired,
  subfleetType: PropTypes.string.isRequired,
  totalRows: PropTypes.number.isRequired,
  className: PropTypes.string,
  enhancedGanttRowHeight: PropTypes.number,
};
