import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import { withAppInsightsTracking } from '../../services/appInsightsFactory/appInsightsFactory';
import { ReactComponent as ConfigurationFileIcon } from '../../assets/images/configuration-file-icon.svg';
import { ReactComponent as ConfigurationTrashIcon } from '../../assets/images/configuration-trash-icon.svg';
import SaveConfigModal from './SaveConfigModal/SaveConfigModal';
import ConfirmationConfigModal from './ConfirmationConfigModal/ConfirmationConfigModal';
import { useConfigStore, useConfigDispatch } from '../../hooks/useConfigStore/useConfigStore';
import { useFetchUserConfigurations } from '../../hooks/useUserConfigurationQuery/useUserConfigurationQuery';
import { utcToStationLocalTime } from '../../lib/dateTimeUtils';
import { useRefetchPageData } from '../../hooks/useRefetchQuery/useRefetchQuery';
import { TimeZones, RefetchPageData } from '../../lib/constants';
import { deleteUserConfiguration } from '../../services/apiClient/userConfigurationsApi/userConfigurationsApi';
import PropTypes from 'prop-types';
import './Configuration.css';

/**
 * Configuration Page component
 * @param {*} children
 * @returns
 */
const ConfigurationPage = ({ children }) => {
  return (
    <div data-cy="configuration-page" className="configuration">
      <div className="page-header">
        <div data-cy="configuration-header-text">Saved Configuration Files</div>
      </div>
      {children}
    </div>
  );
};

/**
 * Configuration Icon component
 * @returns Configuration Icon
 */
const ConfigurationIcon = () => {
  return <ConfigurationFileIcon data-cy="configuration-file-icon" className="configuration-file-icon" />;
};
const initSavableConfigDetails = {
  title: '',
  text: ``,
  secondLineText: '',
  type: '',
  yesButtonText: '',
  noButtonText: '',
  deleteConfirmationId: -1,
};

const Configuration = () => {
  const { openSaveConfig, activeConfigurationId } = useConfigStore();
  const { openSaveConfigModal, closeSaveConfigModal, updateActiveConfigurationId } = useConfigDispatch();

  const { isLoading: isLoadingList, isError: hasListError, userConfigList } = useFetchUserConfigurations();

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [confirmationModalDetails, setConfirmationModalDetails] = useState(initSavableConfigDetails);
  const [configNameToSave, setConfigNameToSave] = useState('');

  const { refetchData } = useRefetchPageData();

  if (isLoadingList) {
    return <ConfigurationPage>Loading...</ConfigurationPage>;
  }

  if (hasListError) {
    return <ConfigurationPage>Failed to retrieve User Configurations. Please try again.</ConfigurationPage>;
  }

  const handleDeleteConfig = (configurationId) => {
    deleteUserConfiguration(configurationId);
    setTimeout(() => {
      refetchData(RefetchPageData.USER_CONFIGURATION_PAGE, true);
    }, 500);
  };

  const handleDeleteConfirm = (id) => {
    setConfirmationModalDetails({
      title: 'Confirm Delete',
      text: 'Deleting the config file cannot be undone. all data within config will be lost',
      secondLineText: '',
      type: CONFIRMATION_MODAL_TYPE.CONFIRM_DELETE,
      yesButtonText: 'Cancel',
      noButtonText: 'Delete',
      deleteConfirmationId: id,
    });
    setOpenConfirmationModal(true);
  };

  const handleApplyConfig = (id) => {
    updateActiveConfigurationId({ activeConfigurationId: id });
  };

  /**
   * @description handling closing add flight modal.
   */
  const handleModalCancel = () => {
    closeSaveConfigModal();
    setConfigNameToSave('');
  };

  const ConfigInputNameStatus = {
    NEW_CONFIG_NAME: 'NEW_CONFIG_NAME',
    ACTIVE_CONFIG_NAME: 'LOADED_CONFIG_NAME',
    EXISTING_ON_OTHER_CONFIG: 'EXISTING_ON_OTHER_CONFIG',
  };
  const configNameAvailabilityStatus = (existingElement) => {
    if (existingElement) {
      if (activeConfigurationId === existingElement.configurationId) {
        return ConfigInputNameStatus.ACTIVE_CONFIG_NAME;
      } else {
        return ConfigInputNameStatus.EXISTING_ON_OTHER_CONFIG;
      }
    } else {
      return ConfigInputNameStatus.NEW_CONFIG_NAME;
    }
  };

  const CONFIRMATION_MODAL_TYPE = {
    CONFIRM_SAVE_AS: 'CONFIRM_SAVE_AS',
    CONFIRM_DELETE: 'CONFIRM_DELETE',
  };

  const handleModalSave = (inputVal) => {
    // setConfigNameToSave(inputVal);
    refetchData(RefetchPageData.USER_CONFIGURATION_PAGE, true);
    setTimeout(() => {
      const existingElement = userConfigList.find((config) => config.configurationName === configNameToSave);

      // Open confirmation modal if the config name is already existing on other config
      if (configNameAvailabilityStatus(existingElement) === ConfigInputNameStatus.EXISTING_ON_OTHER_CONFIG) {
        closeSaveConfigModal();
        setConfirmationModalDetails({
          title: 'Confirm Save As',
          text: `${configNameToSave} already exists.`,
          secondLineText: 'Do you want to replace it?',
          type: CONFIRMATION_MODAL_TYPE.CONFIRM_SAVE_AS,
          yesButtonText: 'Yes',
          noButtonText: 'No',
        });
        setOpenConfirmationModal(true);
      }
    }, 500);
  };

  const handleConfirmationYes = () => {
    setOpenConfirmationModal(false);
    setConfirmationModalDetails(initSavableConfigDetails);
  };
  const handleConfirmationNo = () => {
    //if NO is coming from Delete confirmation modal, delete the config.
    if (confirmationModalDetails.type === CONFIRMATION_MODAL_TYPE.CONFIRM_DELETE) {
      handleDeleteConfig(confirmationModalDetails.deleteConfirmationId);
    }
    setOpenConfirmationModal(false);
    setConfirmationModalDetails(initSavableConfigDetails);
    // if NO is coming from Save As confirmation modal, open Save Config Modal.
    if (confirmationModalDetails.type === CONFIRMATION_MODAL_TYPE.CONFIRM_SAVE_AS) {
      openSaveConfigModal();
    }
  };

  function* transformConfigs(data) {
    if (!data) {
      return null;
    }

    for (const config of data) {
      yield transformConfig(config);
    }
  }
  function transformConfig(config) {
    if (!config) {
      throw new Error('User Configuration is null.');
    }
    const dateUTC = config.modifiedDateUTC || config.createdDateUTC;
    const dateModified = utcToStationLocalTime(dateUTC, TimeZones.PDT, 'YYYY-MM-DD');
    const timeModified = utcToStationLocalTime(dateUTC, TimeZones.PDT, 'HH:mm');
    return {
      ...config,
      dateModified,
      timeModified,
    };
  }

  const onInputChangeHandler = (inputText) => {
    setConfigNameToSave(inputText);
  };

  return (
    <ConfigurationPage>
      <TableContainer
        className="configuration-table-container"
        data-cy="configuration-table-container"
        component={Paper}
      >
        <Table className="configuration-table">
          <TableHead className="equipment-change-table-head">
            <TableRow>
              <TableCell key="table-header-icon" data-cy="table-header-icon">
                {' '}
              </TableCell>
              <TableCell key="table-header-filename" data-cy="table-header-filename" className="th-table-header-cell">
                File Name
              </TableCell>
              <TableCell key="table-header-date" data-cy="table-header-date" className="th-table-header-cell">
                Date Modified
              </TableCell>
              <TableCell
                key="table-header-modifiedby"
                data-cy="table-header-modifiedby"
                className="th-table-header-cell"
              >
                Modified By
              </TableCell>
              <TableCell key="table-header-delete" data-cy="table-header-delete">
                {' '}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody data-cy="configuration-table-body" className="configuration-table-body">
            {Array.from(transformConfigs(userConfigList)).map((config, i) => (
              <TableRow
                key={`table-row-${config.configurationId}`}
                style={{
                  mixBlendMode: config.configurationId === activeConfigurationId ? 'color-dodge' : 'normal',
                }}
              >
                <TableCell key={`row-cell-icon-${config.configurationId}`} className={`td-icon`}>
                  {ConfigurationIcon()}
                </TableCell>
                <TableCell
                  key={`row-cell-file-${config.configurationId}`}
                  className={` config-file td-${config.configurationName}`}
                  data-cy="configuration-configuration-name"
                  onClick={() => handleApplyConfig(config.configurationId)}
                >
                  {config.configurationName}
                </TableCell>
                <TableCell key={`row-cell-date-${config.configurationId}`} className={`td-${config.dateModified}`}>
                  <span>{config.dateModified}</span> <span className="modified-time">{config.timeModified}</span>
                </TableCell>
                <TableCell key={`row-cell-by-${config.configurationId}`} className={`td-${config.userName}`}>
                  {config.userName}
                </TableCell>
                <TableCell key={`row-cell-delete-${config.configurationId}`} className={`td-delete`}>
                  <ConfigurationTrashIcon
                    onClick={() => handleDeleteConfirm(config.configurationId)}
                    data-cy="configuration-trash-icon"
                    className="config-trash-icon"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {openSaveConfig && (
        <SaveConfigModal
          onClose={handleModalCancel}
          showModal={openSaveConfig}
          onSave={handleModalSave}
          onInputChange={onInputChangeHandler}
          configNameToSave={configNameToSave}
        />
      )}
      {openConfirmationModal && (
        <ConfirmationConfigModal
          title={confirmationModalDetails?.title}
          confirmationText={confirmationModalDetails?.text}
          secondLineText={confirmationModalDetails?.secondLineText}
          yesButtonText={confirmationModalDetails?.yesButtonText}
          noButtonText={confirmationModalDetails?.noButtonText}
          onYes={handleConfirmationYes}
          onNo={handleConfirmationNo}
          onModalClose={
            confirmationModalDetails?.type == CONFIRMATION_MODAL_TYPE.CONFIRM_SAVE_AS
              ? handleConfirmationNo
              : handleConfirmationYes
          }
          showModal={openConfirmationModal}
        />
      )}
    </ConfigurationPage>
  );
};

ConfigurationPage.propTypes = {
  children: PropTypes.object.isRequired,
};

// Export and add AppInsights component tracking
export default withAppInsightsTracking(Configuration);
