import { getTimeDifference } from '../../../lib/dateTimeUtils';

import './PuckContainer.css';
import { Treatment } from '../../../lib/constants';
import { useFeatureFlag } from '../../../contexts/FeatureFlagContext/FeatureFlagContext';
import { useSwapModeStore } from '../../../hooks/useSwapModeStore/useSwapModeStore';
import PropTypes from 'prop-types';

/**
 * A PuckContainer is responsible for aligning a given puck's left positioning
 * @param {Object} props
 * @param {number} props.totalRows

 * - props.ganttStartDateTime - when the gantt timeline starts
 * - props.puckStartDateTime - when the puck starts
 * - props.customCSSTag - custom CSS tag for container
 * - props.children - the puck to align
 * @returns
 */
const PuckContainer = ({
  ganttStartDateTime,
  puckStartDateTime,
  customCSSTag = '',
  dataCyTag = 'puck-container',
  children,
  totalRows,
}) => {
  const { showFeature } = useFeatureFlag();
  const enableEnhancedScalingFlag = showFeature(Treatment.SCALING_BUTTON);
  const { isSwapModeActive } = useSwapModeStore();

  const timeFromStart = getTimeDifference(ganttStartDateTime, puckStartDateTime, 'minute');

  const containerStyle =
    !enableEnhancedScalingFlag || isSwapModeActive
      ? {
          position: 'absolute',
          left: `calc(${timeFromStart} * var(--time-scale-minute))`,
          top: totalRows === 1 ? '30%' : '',
          height: '40%',
          lineHeight: '40%',
        }
      : {
          position: 'absolute',
          left: `calc(${timeFromStart} * var(--time-scale-minute))`,
          top: totalRows === 1 ? '20%' : '',
          marginTop: totalRows > 1 && 'var(--puck-margin-top)',
          height: 'var(--puck-height)',
          lineHeight: 'var(--puck-line-height)',
        };

  return (
    <div
      className={`puck-container${!!customCSSTag ? ` ${customCSSTag}` : ''}`}
      style={containerStyle}
      data-cy={dataCyTag}
    >
      {children}
    </div>
  );
};

export default PuckContainer;

PuckContainer.propTypes = {
  totalRows: PropTypes.number.isRequired,
};
