import windowManagerReducer from './windowManagerReducer';
import rootReducer from './rootReducer';

const multipleViewsRootReducer = (state, action) => {
  const activeId = state?.viewManagerReducer?.viewOrder[0] || 0;
  const updatedWindowState = windowManagerReducer(state?.viewManagerReducer, action);
  const update = {
    viewManagerReducer: updatedWindowState,
    viewWindowData:
      updatedWindowState?.viewWindows.map((viewWindow) => {
        const currentId = viewWindow.id;
        let currentIdState = state.viewWindowData?.find((viewData) => viewData.id === currentId);
        if (!currentIdState) {
          currentIdState = rootReducer({ id: currentId }, { type: 'INIT', id: currentId });
        }
        return currentId === activeId ? rootReducer(currentIdState, { ...action }) : currentIdState;
      }) || [],
  };
  return update;
};

export default multipleViewsRootReducer;
