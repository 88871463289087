import { useDragItemStore } from '../../../../hooks/useDragDropStore/useDragDropStore';
import { useDropItem } from '../../../../hooks/useDropItem/useDropItem';
import { useSwapModeDispatch } from '../../../../hooks/useSwapModeStore/useSwapModeStore';
import StationPuck from '../../StationPuck/StationPuck';
import { mapFlightDataToOutput, mapFlightDataToOutputWithKey } from '../../../../lib/swapUtil';
import './SwapStationPuck.css';
import {
  useMultiSelectedFlightGroupStore,
  useMultiSelectedFlightGroupDispatch,
} from '../../../../hooks/useMultiSelectedFlightGroupStore/useMultiSelectedFlightGroupStore';

/**
 * Renders the station puck based on user's theme after swapMode is on
 * @param {*} props
 * @returns
 */
export default function SwapStationPuck(props) {
  const { flightLegGroup } = useMultiSelectedFlightGroupStore();
  const { removeAllMultiSelectedFlightGroup } = useMultiSelectedFlightGroupDispatch();
  const swapModeDispatch = useSwapModeDispatch();
  const dragItem = useDragItemStore();

  const { data } = props;

  const { aircraft, index } = data;

  const [dropRef] = useDropItem({
    dropItemKey: `${aircraft}-${index}`,
    data,
    onDrop: () => {
      const filteredFlightData = dragItem.ctrlKey
        ? [...dragItem.data.filter((f) => !f.groundFlag).map((f) => mapFlightDataToOutputWithKey(f))]
        : [
            ...dragItem.data
              .slice(0, 1)
              .filter((f) => !f.groundFlag)
              .map((f) => mapFlightDataToOutputWithKey(f)),
          ];

      if (
        flightLegGroup[filteredFlightData[0].aircraftRegistration] != undefined &&
        flightLegGroup[filteredFlightData[0].aircraftRegistration].some(
          (x) => x.flightLegKey == filteredFlightData[0].flightLegKey,
        )
      ) {
        let selectedFlights = flightLegGroup[filteredFlightData[0].aircraftRegistration];

        const flightDataToSwap = selectedFlights.filter((f) => !f.groundFlag).map((f) => mapFlightDataToOutput(f));

        swapModeDispatch.updateMoveFlightLeg({
          flightData: flightDataToSwap,
          aircraftRegistration: aircraft,
        });
        removeAllMultiSelectedFlightGroup();
      } else {
        swapModeDispatch.updateMoveFlightLeg({
          flightData: filteredFlightData,
          aircraftRegistration: aircraft,
        });
      }
    },
  });

  return (
    <div ref={dropRef} className="swap-station-puck">
      <StationPuck {...props} />
    </div>
  );
}
