import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import FlightPuck from '../FlightPuck/FlightPuck';
import StationPuck from '../StationPuck/StationPuck';
import StandbyGroundPuck from '../StandbyGroundPuck/StandbyGroundPuck';
import OutOfServiceGroundPuck from '../OutOfServiceGroundPuck/OutOfServiceGroundPuck';
import { GroundEventType, IrropsCode, PuckType, Treatment } from '../../../lib/constants';
import PuckContainer from '../PuckContainer/PuckContainer';
import { getDatetimeUtc, formatDateTime, getTimeDifference } from '../../../lib/dateTimeUtils';
import { useUserPreferencesData } from '../../../contexts/UserPreferencesContext/UserPreferencesContext';
import './LineOfFlight.css';
import dayjs from 'dayjs';
import SwapFlightPuck from '../SwapMode/SwapFlightPuck/SwapFlightPuck';
import SwapStationPuck from '../SwapMode/SwapStationPuck/SwapStationPuck';
import SwapEmptyAircraftContainer from '../SwapMode/SwapEmptyAircraftContainer/SwapEmptyAircraftContainer';
import { useSwapModeStore } from '../../../hooks/useSwapModeStore/useSwapModeStore';
import { getPuckData } from '../GanttChart/ganttHelpers';
import EarlyDelayIndicatorLine from '../EarlyDelayIndicator/EarlyDelayIndicatorLine';
import { getFlightLine } from '../../../lib/swapUtil';
import SwapStandbyGroundPuck from '../SwapMode/SwapStandbyGroundPuck/SwapStandbyGroundPuck';
import { isGroundEventPuck } from '../../../lib/utils';
import { useFeatureFlag } from '../../../contexts/FeatureFlagContext/FeatureFlagContext';

/**
 * LineOfFlight represents one aircraft row in the gantt chart.
 * It is responsible for rendering flight and ground pucks, station labels,
 * and aligning them in the correct row.
 * @param {Object} props
 *  * @param {number} props.totalRows

 * - aircraftRegistration
 * - lineOfFlightConfig - configuration object of pucks to render, see propTypes
 * - ganttStartTime - datetime string when the gantt timeline starts
 * - ganttEndTime - datetime string when the gantt timeline ends
 * - isPaneOpen - whether the detail pane is currently open
 * - summaryPanelMode - the mode of the summary panel
 * - openDetailPane - callback to open the detail pane
 * @returns
 */
const LineOfFlight = ({
  aircraftRegistration,
  lineOfFlightConfig,
  ganttStartTime,
  ganttEndTime,
  isPaneOpen,
  summaryPanelMode,
  handleChangeActivityKey,
  openDetailPane,
  hoursDisplay,
  showRows,
  collapsingRowsFlag,
  focusedFlightLeg,
  extendedStartTimeHours,
  enhancedGanttRowHeight,
  totalRows,
}) => {
  const scheduledPuckConfigs = lineOfFlightConfig.scheduled;
  const canceledPuckConfigs = lineOfFlightConfig.canceled || [];
  const airline = lineOfFlightConfig.airline;
  const { showFeature } = useFeatureFlag();
  const enableEnhancedScalingFlag = showFeature(Treatment.SCALING_BUTTON);
  const { isSwapModeActive } = useSwapModeStore();

  const { state: userPreferencesState } = useUserPreferencesData();

  let groundPuckIndex = 0;
  const ganttStartTimeDayjs = useMemo(() => {
    if (extendedStartTimeHours > 0) {
      return getDatetimeUtc(ganttStartTime).subtract(extendedStartTimeHours, 'hour');
    } else {
      return getDatetimeUtc(ganttStartTime);
    }
  }, [ganttStartTime, extendedStartTimeHours]);

  // Added hoursDisplay for 96hrs bases on the dropdown for the gantt Chart
  //Will be updated to TimeLineHours
  const ganttEndTimeDayjs = useMemo(
    () => getDatetimeUtc(ganttEndTime).add(hoursDisplay, 'hour'),
    [ganttEndTime, hoursDisplay],
  );
  /**
   * Returns gantt start time if puck start time is before gantt startttime
   * @param {string} unAdjustedStartTime - A starttime of the ground puck
   * @returns - gantt starttime
   */
  const getAdjustedStartTime = (unAdjustedStartTime) => {
    if (dayjs(unAdjustedStartTime).isBefore(ganttStartTimeDayjs)) {
      return ganttStartTimeDayjs;
    }
    return unAdjustedStartTime;
  };

  /**
   * Returns gantt end time if puck end time is after gantt endtime
   * @param {string} unAdjustedEndTime - A endtime of the ground puck
   * @returns - gantt endtime
   */
  const getAdjustedEndTime = (unAdjustedEndTime) => {
    if (dayjs(unAdjustedEndTime).isAfter(ganttEndTimeDayjs)) {
      return ganttEndTimeDayjs;
    }
    return unAdjustedEndTime;
  };

  const getGroundPuck = (puckType, puckConfig, nextPuckConfig = null) => {
    const puckConfigData = getPuckData(puckConfig);
    const isFlightPuck = !isGroundEventPuck(puckConfig.puckType);

    const createGroundEventPuck = () => ({
      groundFlag: true,
      puckType: puckType,
      arrivalStation: puckConfigData.groundEventStation,
      arrival: getAdjustedStartTime(puckConfigData.start),
      departureStation: puckConfigData.groundEventStation,
      departure: getAdjustedEndTime(puckConfigData.end),
      aircraft: aircraftRegistration,
      rowLevel: puckConfigData.rowLevel,
      index: groundPuckIndex,
      cancelledFlag: false,
    });

    const getArrivalStation = (isFlightPuck, puckConfigData) => {
      return isFlightPuck
        ? puckConfigData.projectedDestination ?? puckConfigData.dest
        : puckConfigData.groundEventStation;
    };

    const getArrivalTime = (isFlightPuck, puckConfigData) => {
      return getAdjustedStartTime(isFlightPuck ? puckConfigData.arrival : puckConfigData.end);
    };

    const getDepartureStation = (isNextFlightPuck, nextPuckConfigData) => {
      return isNextFlightPuck ? nextPuckConfigData.orig : nextPuckConfigData.groundEventStation;
    };

    const getDepartureTime = (isNextFlightPuck, nextPuckConfigData) => {
      return getAdjustedEndTime(isNextFlightPuck ? nextPuckConfigData.departure : nextPuckConfigData.start);
    };

    const createGroundTurnPostPuck = () => {
      const arrivalStation = getArrivalStation(isFlightPuck, puckConfigData);
      const arrival = getArrivalTime(isFlightPuck, puckConfigData);
      const departureStation = nextPuckConfig
        ? getDepartureStation(!isGroundEventPuck(nextPuckConfig.puckType), getPuckData(nextPuckConfig))
        : null;
      const departure = nextPuckConfig
        ? getDepartureTime(!isGroundEventPuck(nextPuckConfig.puckType), getPuckData(nextPuckConfig))
        : null;

      return {
        groundFlag: true,
        arrivalStation,
        arrival,
        departureStation,
        departure,
        aircraft: aircraftRegistration,
        rowLevel: puckConfigData.rowLevel,
        index: groundPuckIndex,
        cancelledFlag: puckConfigData.irropsCode === IrropsCode.CANCELLED_FLIGHT,
        puckType: PuckType.GROUND_TURN_POST,
      };
    };
    const createGroundTurnPrePuck = () => ({
      groundFlag: true,
      arrivalStation: null,
      arrival: formatDateTime(ganttStartTimeDayjs, 'YYYY-MM-DDTHH:mm:ssZ'),
      departureStation: isFlightPuck ? puckConfigData.orig : puckConfigData.groundEventStation,
      departure: getAdjustedEndTime(isFlightPuck ? puckConfigData.departure : puckConfigData.start),
      aircraft: aircraftRegistration,
      rowLevel: puckConfigData.rowLevel,
      index: groundPuckIndex,
      cancelledFlag: puckConfigData.irropsCode === IrropsCode.CANCELLED_FLIGHT,
      puckType: PuckType.GROUND_TURN_PRE,
    });

    let puck = null;
    if (isGroundEventPuck(puckType)) {
      puck = createGroundEventPuck();
    } else if (puckType === PuckType.GROUND_TURN_POST) {
      puck = createGroundTurnPostPuck();
    } else if (puckType === PuckType.GROUND_TURN_PRE) {
      puck = createGroundTurnPrePuck();
    }

    if (puck) {
      groundPuckIndex++;
    }
    return puck;
  };

  /**
   * @description - Adds a puck to the specified row to content
   * @param {Object} puck - ground puck or flight puck
   * @param {*} index - row (0-based) to add puck to
   */
  const pushContent = (puck, index) => {
    if (puck) {
      content[index].push(puck);
    }
  };

  // 2d array where each array is one row in this line of flight
  let content = [];

  // initialize content with the number of rows needed
  for (let i = 0; i < lineOfFlightConfig.totalRows; i++) {
    content.push([]);
  }
  // Add all scheduled pucks and the necessary station ground pucks into content
  scheduledPuckConfigs.forEach((puckConfig, puckIndex) => {
    let index = puckConfig.rowLevel - 1;
    const puckType = puckConfig.puckType;
    const data = getPuckData(puckConfig);
    const isGroundEvent = isGroundEventPuck(puckType);
    // PRE GROUND PUCK
    if (!content[index]) {
      index = 0;
    }
    const puckStartTime = getAdjustedStartTime(isGroundEvent ? data.start : data.departure);
    if (content[index].length === 0 && getDatetimeUtc(puckStartTime) > ganttStartTimeDayjs) {
      let preGroundPuck = getGroundPuck(PuckType.GROUND_TURN_PRE, puckConfig);
      pushContent(preGroundPuck, index);
    }

    // FLIGHT OR GROUND EVENT PUCK
    if (isGroundEvent) {
      let groundEventPuck;
      if (
        data.groundEventType === GroundEventType.UNSCHEDULED_OTS ||
        data.groundEventType === GroundEventType.SCHEDULED_OTS ||
        data.groundEventType === GroundEventType.UNAVAILABLE
      ) {
        groundEventPuck = {
          aircraft: aircraftRegistration,
          puckType: puckConfig.puckType,
          ...data,
          start: getAdjustedStartTime(data?.start),
          end: getAdjustedEndTime(data?.end),
        };
      } else {
        groundEventPuck = getGroundPuck(puckType, puckConfig);
      }
      pushContent(groundEventPuck, index);
    } else {
      pushContent({ ...data, index: puckIndex }, index);
    }

    // POST GROUND PUCK
    let nextPuckConfig = puckIndex + 1 < scheduledPuckConfigs.length ? scheduledPuckConfigs[puckIndex + 1] : null;
    let postGroundPuck = getGroundPuck(PuckType.GROUND_TURN_POST, puckConfig, nextPuckConfig);
    pushContent(postGroundPuck, index);
  });

  // Add all canceled pucks and the necessary station ground pucks into content
  canceledPuckConfigs.forEach((puckConfig) => {
    let index = puckConfig.rowLevel - 1;

    const data = getPuckData(puckConfig);

    // PRE GROUND PUCK
    if (content[index].length === 0 && getDatetimeUtc(data.departure) >= ganttStartTimeDayjs) {
      let preGroundPuck = getGroundPuck(PuckType.GROUND_TURN_PRE, puckConfig);
      pushContent(preGroundPuck, index);
    } else {
      let currentContentRow = content[index];
      let prevConfig = currentContentRow[currentContentRow.length - 1];
      const duration = getTimeDifference(prevConfig.arrival, data.departure, 'minutes');
      const SharedGroundPuckMinDuration = 60;
      if (duration > SharedGroundPuckMinDuration) {
        // two canceled pucks are far enough that they should have their own labels
        // SEA [PUCK] PDX                       *PDX* [PUCK]
        let preGroundPuck = getGroundPuck(PuckType.GROUND_TURN_PRE, puckConfig);
        pushContent(preGroundPuck, index);
      } else {
        // two canceled pucks are close enough that they can share the same label.
        // modify previous ground puck to add current canceled puck's departure info to center label and show mismatches if applicable.
        // SEA [PUCK] *PDX* [PUCK]
        prevConfig.departureStation = data.orig;
        prevConfig.departure = data.departure;
      }
    }

    // CANCELED PUCK
    pushContent(data, index);

    // POST GROUND PUCK
    let postGroundPuck = getGroundPuck(PuckType.GROUND_TURN_POST, puckConfig);
    groundPuckIndex++;

    pushContent(postGroundPuck, index);
  });

  // Define the useCallback hook with the function dependencies
  const getFlightLineForPuckCallback = useCallback(
    (puck, ctrlKey, showAll) => {
      return getFlightLine(puck, scheduledPuckConfigs, ctrlKey, showAll);
    },
    [scheduledPuckConfigs],
  );

  const timelineHours = useMemo(
    () =>
      enableEnhancedScalingFlag
        ? userPreferencesState?.ganttViewScaling?.hoursBefore + userPreferencesState?.ganttViewScaling.hoursAfter
        : userPreferencesState.timelineHours.timelineHours,
    [userPreferencesState],
  );

  return (
    <>
      {
        // within each aircraft schedule row, render the flight or ground time pucks and set their left position
        content.map((row, index) => {
          if (!focusedFlightLeg && collapsingRowsFlag && !showRows[index]) {
            return null;
          }
          const rowStyle =
            enableEnhancedScalingFlag && enhancedGanttRowHeight
              ? {
                  height: `${enhancedGanttRowHeight}px`,
                  lineHeight: `${enhancedGanttRowHeight}px`,
                }
              : {
                  height: 'var(--gantt-row-height)',
                  lineHeight: 'var(--gantt-row-height)',
                };

          return (
            <div
              className="aircraft-row-level"
              style={rowStyle}
              key={`aircraft-row-level-${index + 1}`}
              data-cy={`${aircraftRegistration}-row-level-${index + 1}`}
            >
              {isSwapModeActive && row.length === 0 ? (
                <SwapEmptyAircraftContainer aircraftRegistration={aircraftRegistration} />
              ) : (
                row.map((puck, i) => {
                  if (puck.puckType === PuckType.GROUND_OTS || puck.puckType === PuckType.GROUND_UNAVAILABLE) {
                    return (
                      <PuckContainer
                        ganttStartDateTime={ganttStartTimeDayjs}
                        puckStartDateTime={puck.start}
                        key={`puck-container-${i}`}
                        enhancedGanttRowHeight={enhancedGanttRowHeight}
                        totalRows={totalRows}
                      >
                        <OutOfServiceGroundPuck
                          data={{ ...puck }}
                          openDetailPane={openDetailPane}
                          airline={airline}
                          isPaneOpen={isPaneOpen}
                          summaryPanelMode={summaryPanelMode}
                          handleChangeActivityKey={handleChangeActivityKey}
                          getFlightLineForFlightPuck={(ctrlKey, showAll) =>
                            getFlightLineForPuckCallback(puck, ctrlKey, showAll)
                          }
                        />
                      </PuckContainer>
                    );
                  } else if (puck.puckType === PuckType.GROUND_STANDBY) {
                    return (
                      <PuckContainer
                        ganttStartDateTime={ganttStartTimeDayjs}
                        puckStartDateTime={puck.arrival}
                        key={`puck-container-${i}`}
                        totalRows={totalRows}
                      >
                        {isSwapModeActive ? (
                          <SwapStandbyGroundPuck
                            data={{ ...puck }}
                            handleChangeActivityKey={handleChangeActivityKey}
                            summaryPanelMode={summaryPanelMode}
                            openDetailPane={openDetailPane}
                            isPaneOpen={isPaneOpen}
                            getFlightLineForFlightPuck={(ctrlKey, showAll) =>
                              getFlightLineForPuckCallback(puck, ctrlKey, showAll)
                            }
                          />
                        ) : (
                          <StandbyGroundPuck
                            data={{ ...puck }}
                            openDetailPane={openDetailPane}
                            airline={airline}
                            isPaneOpen={isPaneOpen}
                            summaryPanelMode={summaryPanelMode}
                            handleChangeActivityKey={handleChangeActivityKey}
                            getFlightLineForFlightPuck={(ctrlKey, showAll) =>
                              getFlightLineForPuckCallback(puck, ctrlKey, showAll)
                            }
                          />
                        )}
                      </PuckContainer>
                    );
                  } else if (!puck.groundFlag) {
                    return (
                      <PuckContainer
                        ganttStartDateTime={ganttStartTimeDayjs}
                        puckStartDateTime={puck.departure}
                        dataCyTag={`flight-puck-container-${puck.flightLegKey}`}
                        customCSSTag="flight-puck-container"
                        key={`puck-${i}`}
                        totalRows={totalRows}
                      >
                        <EarlyDelayIndicatorLine data={puck}>
                          {isSwapModeActive ? (
                            <SwapFlightPuck
                              key={`flight-puck-${puck.flightLegKey}${timelineHours}`}
                              data={puck}
                              ganttStartTime={ganttStartTimeDayjs}
                              openDetailPane={openDetailPane}
                              isPaneOpen={isPaneOpen}
                              summaryPanelMode={summaryPanelMode}
                              handleChangeActivityKey={handleChangeActivityKey}
                              isWatchFlight={puck.isWatchFlight}
                              isInfoBy={puck.isInfoBy}
                              customCSSTag="flight-puck-container"
                              getFlightLineForFlightPuck={(ctrlKey, showAll) =>
                                getFlightLineForPuckCallback(puck, ctrlKey, showAll)
                              }
                            />
                          ) : (
                            <FlightPuck
                              key={`flight-puck-${puck.flightLegKey}${timelineHours}`}
                              data={puck}
                              ganttStartTime={ganttStartTimeDayjs}
                              openDetailPane={openDetailPane}
                              isPaneOpen={isPaneOpen}
                              summaryPanelMode={summaryPanelMode}
                              handleChangeActivityKey={handleChangeActivityKey}
                              isWatchFlight={puck.isWatchFlight}
                              isInfoBy={puck.isInfoBy}
                              getFlightLineForFlightPuck={(ctrlKey, showAll) =>
                                getFlightLineForPuckCallback(puck, ctrlKey, showAll)
                              }
                            />
                          )}
                        </EarlyDelayIndicatorLine>
                      </PuckContainer>
                    );
                  } else {
                    return (
                      <PuckContainer
                        ganttStartDateTime={ganttStartTimeDayjs}
                        puckStartDateTime={puck.arrival}
                        key={`puck-${i}`}
                        totalRows={totalRows}
                      >
                        {isSwapModeActive ? (
                          <SwapStationPuck
                            key={`ground-time-puck-${puck.aircraft}-${puck.index}`}
                            data={puck}
                            ganttStartTime={ganttStartTime}
                          />
                        ) : (
                          <StationPuck
                            key={`ground-time-puck-${puck.aircraft}-${puck.index}`}
                            data={puck}
                            ganttStartTime={ganttStartTime}
                          />
                        )}
                      </PuckContainer>
                    );
                  }
                })
              )}
            </div>
          );
        })
      }
    </>
  );
};

LineOfFlight.propTypes = {
  aircraftRegistration: PropTypes.string.isRequired,
  lineOfFlightConfig: PropTypes.shape({
    totalRows: PropTypes.number.isRequired,
    scheduled: PropTypes.arrayOf(
      PropTypes.shape({
        puckType: PropTypes.oneOf(Object.entries(PuckType).map(([k, v]) => v)),
        rowLevel: PropTypes.number.isRequired,
        data: PropTypes.oneOfType([
          PropTypes.shape({
            flightLegKey: PropTypes.number.isRequired,
            flightNumber: PropTypes.number.isRequired,
            orig: PropTypes.string.isRequired,
            dest: PropTypes.string.isRequired,
            departure: PropTypes.string.isRequired,
            arrival: PropTypes.string.isRequired,
            aircraft: PropTypes.string.isRequired,
          }).isRequired,
          PropTypes.shape({
            groundEventKey: PropTypes.number.isRequired,
            groundEventType: PropTypes.string.isRequired,
            state: PropTypes.string.isRequired,
            groundEventStation: PropTypes.string.isRequired,
            start: PropTypes.string.isRequired,
            end: PropTypes.string.isRequired,
            airline: PropTypes.string.isRequired,
            aircraft: PropTypes.string.isRequired,
          }).isRequired,
        ]),
      }).isRequired,
    ),
    canceled: PropTypes.arrayOf(
      PropTypes.shape({
        flightLegKey: PropTypes.number.isRequired,
        flightNumber: PropTypes.number.isRequired,
        orig: PropTypes.string.isRequired,
        dest: PropTypes.string.isRequired,
        departure: PropTypes.string.isRequired,
        arrival: PropTypes.string.isRequired,
        aircraft: PropTypes.string.isRequired,
        rowLevel: PropTypes.number.isRequired,
      }).isRequired,
    ),
    airline: PropTypes.string.isRequired,
    aircraft: PropTypes.string.isRequired,
    fleetType: PropTypes.string.isRequired,
    subfleetSortOrder: PropTypes.number.isRequired,
    airlineSortOrder: PropTypes.number.isRequired,
  }),
  ganttStartTime: PropTypes.string.isRequired,
  ganttEndTime: PropTypes.string.isRequired,
  isPaneOpen: PropTypes.bool.isRequired,
  summaryPanelMode: PropTypes.string.isRequired,
  handleChangeActivityKey: PropTypes.func.isRequired,
  openDetailPane: PropTypes.func.isRequired,
  hoursDisplay: PropTypes.string.isRequired,
  showRows: PropTypes.array.isRequired,
  collapsingRowsFlag: PropTypes.bool.isRequired,
  focusedFlightLeg: PropTypes.number,
  extendedStartTimeHours: PropTypes.number,
  enhancedGanttRowHeight: PropTypes.number,
  totalRows: PropTypes.number.isRequired,
};

export default React.memo(LineOfFlight);
